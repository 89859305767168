<template>
  <div class="card-announcement py-4 px-3" :class="isUnread ? 'card-announcement-unread' : null">
    <div class="d-flex align-items-center justify-content-between">
      <div class="mb-1 font-weight-bold font-size-18 title mb-0">{{ item.judul }}</div>
      <div v-if="isUnread"><a-icon type="exclamation-circle" class="hsn-icon" /></div>
    </div>
    <p class="small meta">
      {{ moment(item.updatedAt).format('dddd, DD MMMM YYYY') }} by {{item.guru ? item.guru.nama : 'Admin'}}
    </p>
    <div class="info mt-2 font-size-14">
      <span v-html="clearTag(item.deskripsi)"></span>
    </div>
  </div>
</template>

<script>
import { clearTag } from '@/helpers/regex.js'
import moment from 'moment'

export default {
  props: {
    item: {
      type: Object,
    },
    role: {
      type: String,
    },
  },
  methods: {
    clearTag,
    moment,
  },
  computed: {
    isUnread() {
      // return !this.item.pengumuman_terbacas?.length && this.role === 'Student'
      return !this.item.pengumuman_terbacas?.length && this.role === 'Student'
    },
  },
}
</script>

<style lang="scss">

</style>
